import { useStaticQuery, graphql } from "gatsby"

export const useFundFactSheetsData = () => {
  const data = useStaticQuery(
    graphql`
      query FundFactSheetsQuery {
        allPrismicFundFactSheets(sort: { fields: data___month, order: DESC }) {
          edges {
            node {
              data {
                files {
                  fund_fact_sheet_file {
                    url
                  }
                  fund_label {
                    text
                  }
                }
                month(formatString: "MMMM YYYY")
              }
            }
          }
        }
      }
    `
  )
  return data.allPrismicFundFactSheets.edges
}
