import * as React from "react"
import styled from "styled-components"
import HeroBkg from "../microsites/investor-relations/images/dfy-RlIgBOphKxU-unsplash.jpg"
import Header from "../components/layout/header"
import Meta from "../utilities/seo"
import DocumentItem from "../microsites/investor-relations/document-list-item"
import SectionTitle from "../microsites/investor-relations/section-title"
import SectionContainer from "../microsites/investor-relations/section-container"
import { useFundFactSheetsData } from "../microsites/fund-fact-sheets/data/fund-fact-sheets-data"
import Footer from "../components/layout/footer"

const Splash = styled.div`
  height: 38vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-blue-200);
  overflow: hidden;
  position: relative;

  h1 {
    font-family: var(--xn-font-body);
    font-weight: bold;
    color: #fff;
    font-size: 3rem;
    letter-spacing: -3px;
    position: relative;
    z-index: 1;
    padding-top: 5vh;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
`

const Hero = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 90%;

  img {
    width: 100%;
    height: 100%;
    transform: scale3d(-1, 1, 1);
    object-fit: cover;
    opacity: 40%;
    ${"" /* filter: grayscale(100%); */}
  }
`

const Box = styled.main`
  background-color: #000;
  min-height: 100vh;
  padding-bottom: 2rem;

  .collection {
    padding: 1.5rem;
    border-radius: 0.75rem;
    margin-bottom: 3rem;
    background: #75cbba17;
  }
`

const Ele = () => {
  const data = useFundFactSheetsData()
  console.log(data)

  // const reports = data.allFile.edges

  React.useEffect(() => {
    let html = document.documentElement
    html.classList.add("is-dark")

    return () => {
      html.classList.remove("is-dark")
    }
  }, [])

  return (
    <>
      <Meta
        title="Fund Fact Sheets"
        description="Get an overview of XENO funds including fund investment objectives, risk levels, costs, past performance and holdings details."
        image={{
          url: "https://res.cloudinary.com/xeno-technologies/image/upload/c_fill,h_1080,w_1920/v1674160220/noah-windler-gQI8BOaL69o-unsplash_oth7lp.jpg",
          height: "1920",
          width: "1080",
        }}
      />
      <Header />
      <Box>
        <Splash>
          <h1>Fund Fact Sheets</h1>
          <Hero>
            <img src={HeroBkg} alt="" />
          </Hero>
        </Splash>
        <SectionContainer>
          {data &&
            data.length &&
            data.map((collection, index) => {
              let node = collection.node.data
              let title = node.month
              let files = node.files

              return (
                <section key={index} className="collection">
                  <SectionTitle text={title} />
                  <ul>
                    {files &&
                      files.length &&
                      files.map((file, fileIndex) => {
                        let fileName = file.fund_label.text
                        let fileUrl = file.fund_fact_sheet_file.url

                        return (
                          <li key={fileIndex}>
                            <a href={fileUrl} target="_blank">
                              <DocumentItem label={fileName}></DocumentItem>
                            </a>
                          </li>
                        )
                      })}
                  </ul>
                </section>
              )
            })}
        </SectionContainer>
      </Box>
      <Footer theme="light" />
    </>
  )
}
export default Ele
